import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO
        title="About"
        keywords={[
          `Parametric`,
          `Wall Sculpture`,
          `Contemporary Art`,
          `Dimensional Wall Art`,
          `3D Wall Art`,
          `Modern Art`,
          `Flower Art`,
          `Mosaic Art`,
          `Wood Wall Art`,
          `Sacred Geometry`,
          `Abstract Art`,
          `Wall Object`,
          `3D Printing`,
          `Mandala Art`,
          `Geometric Art`,
          `Trippy Art`,
        ]}
      />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h2 id="clean-minimal-and-deeply-customisable-london-is-a-theme-made-for-people-who-appreciate-simple-lines-">
          Fusing Organic Forms with Digital Precision
          </h2>
          <figure className="kg-card kg-image-card kg-width-wide">
            <GatsbyImage
              image={data.benchAccounting.childImageSharp.gatsbyImageData}
              className="kg-image"
            />
            <figcaption>Assembling "Pine Stone" Six Petal Sculpture</figcaption>
          </figure>
          <h3 id="dynamic-styles">About the Artist</h3>

          <p>Herschel Shapiro is the creator of "Organic Precision," a style that merges the mathematical beauty of nature with the exactitude of modern digital fabrication techniques. His work explores the eternal patterns and dynamic flows found in the natural world—such as the spirals of a seashell or the vertices of a whirlwind—patterns that have shaped both art and science for millennia.</p>

          <p>Central to Herschel's approach is the synthesis of collections of elements with organic shapes, carefully combined to form intricate patterns. Each piece he creates is not just a collection of parts, but a cohesive whole that transcends its individual components, revealing a deeper connection between the elements. This interplay between structure and dynamic flow is at the heart of his sculptures, where the whole truly becomes greater than the sum of its parts.</p>

          <p>Using advanced 3D printing technology, Herschel captures the timeless elegance of natural patterns, including the complex flows of vector fields, creating forms that can only be realized through the meticulous processes of digital design and fabrication. His art delves into the profound mathematical truths of nature, brought to life in three-dimensional forms that embody both precision and organic fluidity.</p>

          <p>Herschel's dedication to innovation drives him to constantly push the boundaries of what can be achieved with contemporary materials and techniques. His sculptures are as durable and sustainable as they are visually stunning, reflecting a commitment to bridging the gap between tradition and modernity.</p>

          <p>Through his work, Herschel invites viewers to engage with the world in a new way, seeing the intersection of technology, nature, and flow as complementary forces that reveal deeper, eternal truths.</p>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    benchAccounting: file(relativePath: { eq: "herschel.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 1360)
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
